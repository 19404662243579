<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link to="/" class="d-flex align-center">
            <h2 class="text-2xl font-weight-semibold">{{ webname }}</h2>
          </router-link>
        </v-card-title>

        <!-- login form -->
        <v-card-text>
          <v-form ref="form">
            <v-text-field
              v-model="username"
              outlined
              label="Username"
              placeholder="ex. akokbet007"
              hide-details
              class="mb-3"
              :rules="[value => !!value || 'Username is required.']"
            ></v-text-field>

            <v-text-field
              v-model="password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Password"
              placeholder="············"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              :rules="[value => !!value || 'Password is required.']"
              @click:append="isPasswordVisible = !isPasswordVisible"
              @keyup.enter="login"
            ></v-text-field>

            <v-btn block color="primary" :loading="loading" class="mt-6" @click="login"> Login </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
 data: () => ({
   loading: false,
   webname: null,
 }),

 async created() {
   await this.$store.dispatch('webSettingInfo')
   this.webname = await this.$store.getters.getWebName
 },

 setup() {
   const isPasswordVisible = ref(false)
   const username = ref('')
   const password = ref('')

   return {
     isPasswordVisible,
     username,
     password,
     icons: {
       mdiEyeOutline,
       mdiEyeOffOutline,
     },
   }
 },

 methods: {
  async handleSuccessfulLogin() {
    await this.$store.dispatch('permissions')
    let psSystem = this.$store.getters.getPermissions
    if (psSystem.length > 0) {
      const dashboardPermission = psSystem.filter(menu => menu === 'dashboard')
      const redirectTo = dashboardPermission.length > 0 ? 'dashboard' : psSystem[0]
      this.$router.push({ path: `/${redirectTo}` })
    } else {
      this.$swal.fire(`คุณไม่มีสิทธิ์เข้าระบบ ADMIN`)
    }
  },

  async login() {
    this.loading = true;

    if (this.$refs.form.validate()) {
      try {
        // Login request that lets backend handle authentication method
        const loginResponse = await this.$store.dispatch('login', {
          username: this.username,
          password: this.password,
        });

        console.log('Login Response:', loginResponse); // Add this log
        // Handle different authentication methods based on backend response
        switch (loginResponse.method) {
          case 'check_2fa':
            this.show2FAPrompt();
            break;

          case 'check_line_otp':
            this.showLineOtpPrompt(loginResponse);
            break;

          case 'check_telegram_otp':  
            this.showTelegramOtpPrompt(loginResponse);
            break;

          default:
            // Normal login with access token
            if (loginResponse.access_token) {
              await this.$store.commit('updateToken', loginResponse.access_token);
              await this.handleSuccessfulLogin();
            } else {
              throw new Error('Login failed');
            }
            break;
        }
      } catch (err) {
        console.error('Login error:', err);
        this.$swal.fire(`${err.message}`);
      }
    }
    this.loading = false;
  },

  show2FAPrompt() {
    this.$swal({
      title: '2FA',
      html: `
        <div>
          <p>ใส่รหัส 6 หลัก</p>
        </div>
      `,
      input: 'text',
      inputAttributes: {
        inputmode: 'numeric',
        pattern: '[0-9]*',
        maxlength: 6,
      },
      inputValidator: value => {
        if (!value || isNaN(value)) {
          return 'โปรดกรอกเฉพาะตัวเลขเท่านั้น'
        }
        if (value && value.length !== 6) {
          return 'โปรดกรอกให้ครบ 6 หลัก'
        }
      },
      inputPlaceholder: 'ใส่รหัสที่นี่...',
      showCancelButton: true,
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ออก',
    }).then(async result => {
      if (result.isConfirmed) {
        await this.login2fa(result.value);
      }
    });
  },

  //Show lineOtp
  showLineOtpPrompt(loginResponse) {
    // Store login credentials before showing the OTP prompt
    this.$store.commit('lineOtpAuth/SET_LOGIN_CREDENTIALS', {
      username: this.username,
      password: this.password
    });

    this.$swal({
      title: 'Line OTP',
      html: `
        <div>
          <p>กรุณากรอกรหัส OTP ที่ส่งผ่าน Line</p>
          <p>Reference: ${loginResponse.reference}</p>
        </div>
      `,
      input: 'text',
      inputAttributes: {
        inputmode: 'numeric',
        pattern: '[0-9]*',
        maxlength: 4,
      },
      inputValidator: value => {
        if (!value || isNaN(value)) {
          return 'โปรดกรอกเฉพาะตัวเลขเท่านั้น'
        }
        if (value && value.length !== 4) {
          return 'โปรดกรอกให้ครบ 4 หลัก'
        }
      },
      inputPlaceholder: 'ใส่รหัสที่นี่...',
      showCancelButton: true,
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ออก',
    }).then(async result => {
      if (result.isConfirmed) {
        await this.verifyLineOtp(result.value, loginResponse);
      } else {
        // Clear stored credentials if cancelled
        this.$store.commit('lineOtpAuth/CLEAR_LOGIN_CREDENTIALS');
        await this.$store.dispatch('logout');
      }
    });
  },
  // end showLineOtpPrompt

  // for 2fa
  async login2fa(pin) {
  try {
    const response = await this.$store.dispatch('login2fa', {
      username: this.username,
      password: this.password,
      pin: pin
    });

      // Handle successful login
      await this.handleSuccessfulLogin();
    } catch (err) {
      // Use SweetAlert to show the error message
      this.$swal.fire({
        icon: 'error',
        title: 'Login Failed',
        text: err.message || 'Two-factor authentication failed'
      });
    }
  },

  //start verifyLineOtp
  async verifyLineOtp(otp, loginResponse) {
    try {
      console.log('Login.vue: Verifying OTP with:', {
        otp,
        reference: loginResponse.reference
      });

      const response = await this.$store.dispatch('lineOtpAuth/verifyLineOtp', {
        otp,
        reference: loginResponse.reference
      });

      console.log('Login.vue: OTP verification response:', response);

      if (response?.data?.access_token) {
        await this.$store.commit('updateToken', response.data.access_token);
        await this.handleSuccessfulLogin();
      } else {
        throw new Error('No access token received');
      }
    } catch (err) {
      console.error('Login.vue: OTP verification error:', err);
      let errorMessage = 'An error occurred during verification';

      try {
        const parsedError = JSON.parse(err.message);
        errorMessage = parsedError.message || errorMessage;
      } catch (parseError) {
        errorMessage = err.message || errorMessage;
      }

      this.$swal.fire({
        icon: 'error',
        title: 'Login Failed',
        text: errorMessage
      });

      await this.$store.dispatch('logout');
    } finally {
      this.$store.commit('lineOtpAuth/CLEAR_LOGIN_CREDENTIALS');
    }
  },
  //end verify lineOtp

  //start showTelegramOtpPrompt
  async showTelegramOtpPrompt(loginResponse) {
    // Store login credentials before showing the OTP prompt
    this.$store.commit('telegramOtpAuth/SET_LOGIN_CREDENTIALS', {
      username: this.username,
      password: this.password
    });

    this.$swal({
      title: 'Telegram OTP',
      html: `
        <div>
          <p>กรุณากรอกรหัส OTP ที่ส่งผ่าน Telegram</p>
          <p>Reference: ${loginResponse.reference}</p>
        </div>
      `,
      input: 'text',
      inputAttributes: {
        inputmode: 'numeric',
        pattern: '[0-9]*',
        maxlength: 4,
      },
      inputValidator: value => {
        if (!value || isNaN(value)) {
          return 'โปรดกรอกเฉพาะตัวเลขเท่านั้น'
        }
        if (value && value.length !== 4) {
          return 'โปรดกรอกให้ครบ 4 หลัก'
        }
      },
      inputPlaceholder: 'ใส่รหัสที่นี่...',
      showCancelButton: true,
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ออก',
    }).then(async result => {
      if (result.isConfirmed) {
        await this.verifyTelegramOtp(result.value, loginResponse);
      } else {
        // Clear stored credentials if cancelled
        this.$store.commit('telegramOtpAuth/CLEAR_LOGIN_CREDENTIALS');
        await this.$store.dispatch('logout');
      }
    });
  },
  //end showTelegramOtpPrompt

  //start verifyTelegramOtp
  async verifyTelegramOtp(otp, loginResponse) {
    try {
      console.log('Login.vue: Verifying OTP with:', {
        otp,
        reference: loginResponse.reference
      });

      const response = await this.$store.dispatch('telegramOtpAuth/verifyTelegramOtp', {
        otp,
        reference: loginResponse.reference
      });

      console.log('Login.vue: OTP verification response:', response);

      if (response?.data?.access_token) {
        await this.$store.commit('updateToken', response.data.access_token);
        await this.handleSuccessfulLogin();
      } else {
        throw new Error('No access token received');
      }
    } catch (err) {
      console.error('Login.vue: OTP verification error:', err);
      let errorMessage = 'An error occurred during verification';

      try {
        const parsedError = JSON.parse(err.message);
        errorMessage = parsedError.message || errorMessage;
      } catch (parseError) {
        errorMessage = err.message || errorMessage;
      }

      this.$swal.fire({
        icon: 'error',
        title: 'Login Failed',
        text: errorMessage
      });

      await this.$store.dispatch('logout');
    } finally {
      this.$store.commit('telegramOtpAuth/CLEAR_LOGIN_CREDENTIALS');
    }
  },
    //end verifyTelegramOtp
},
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>